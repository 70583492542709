import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Button,
    Modal, ModalBody
} from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import Utils from '../Utility'

import avatar from "../../assets/images/small/sample.png"
// actions
import { post, upload, put, get, uploadS3 } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import ProgressBar from "../../components/ProgressBar";

const AddProperty = props => {
    const { user, data, setLoading } = props;
    const [file, setFile] = useState(null);
    const [imageChanged, setImageChanged] = useState(false);
    const imageInput = useRef();
    const imageInputMore = useRef();
    const cropper = useRef();
    const [meta, setMeta] = useState(null);
    const [descContent, setDescContent] = useState(null);
    const [desc, setDesc] = useState("");
    const [cropperModal, setCropperModal] = useState(false);
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [selectedExp, setSelectedExp] = useState([]);
    // const [selectedInsp, setSelectedInsp] = useState([]);
    const [selectedWho, setSelectedWho] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [moreImageModel, setMoreImageModel] = useState(false);
    const [moreFile, setMoreFiles] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentFileType, setCurrentFileType] = useState('file');
    const [currentData, setCurrentData] = useState(null);
    const [countries, setCountries] = useState([]);
    const [progress, setProgress] = useState(0);
    // console.log('props on company', props?.data);

    async function handleValidSubmit(event, values) {
        console.log('values from form', values, file, typeof file);
        // setLoading(true);
        let body = {
            ...values,
            desc: desc,
            monthId: selectedMonths,
            experienceId: selectedExp,
            whoId: selectedWho,
            locationId: selectedLocation,
            token: user?.token
        }
        if (!file) {
            toast.error("Please select the image banner!")
            return;
        }
        if (file.substring(0, 4) == "data") {
            let temp = await urltoFile(file, new Date().getTime() + '.png', 'image/png');
            temp = await resizeFile(temp);
            const uploadedBanner = await uploadS3("property", temp, "webp", onProgress);
            if (uploadedBanner?.statusCode == 200)
                body.banner = uploadedBanner?.data;
            setProgress(0);
        }
        if (data) {
            body.propertyId = data?._id;
            let url = "/property/update";
            console.log('body after upload images', body);
            put(url, body)
                .then((json) => {
                    console.log('response from adding comp', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                        console.log('property added');
                        toast.success(json?.message);
                        props.close(false);
                    } else {
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    console.error('error while adding oppor', error);
                    toast.error(JSON.stringify(error));
                    setLoading(false);
                })
        } else {
            let url = "/property/add";
            console.log('body after upload images', body);
            post(url, body)
                .then((json) => {
                    console.log('response from adding comp', json);
                    setLoading(false);
                    if (json.statusCode == 200) {
                        console.log('property added');
                        toast.success(json?.message);
                        // setCurrentData({ _id: json?.data?._id })
                        props.close(false);
                        // setMoreImageModel(true);
                    } else {
                        toast.error(json?.error);
                    }
                })
                .catch(error => {
                    console.error('error while adding oppor', error);
                    toast.error(JSON.stringify(error));
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        if (data) {
            if (data?.desc) {
                const contentBlock = htmlToDraft(data?.desc);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const editorState = EditorState.createWithContent(contentState);
                    setDescContent(editorState);
                    setDesc(data?.terms);
                }
            }
            if (data?.monthId)
                setSelectedMonths(data?.monthId);
            if (data?.experienceId)
                setSelectedExp(data?.experienceId);
            // if (data?.inspirationId)
            //     setSelectedInsp(data?.inspirationId);
            if (data?.whoId)
                setSelectedWho(data?.whoId);
            if (data?.locationId)
                setSelectedLocation(data?.locationId);
            if (data?.banner)
                setFile(data?.banner);
            if (data?.continentId)
                handleContinentChanged(data?.continentId);
        }
    }, [data, meta]);

    useEffect(() => {
        get('/property/meta')
            .then(res => {
                setMeta(res?.data);
            })
        if (user.role == 'Sub')
            setFile("https://eeasy.s3.ap-south-1.amazonaws.com/Property/1694508297127.webp")
    }, [user]);

    const onProgress = (p) => {
        setProgress(p);
    }

    const onChangeFile = async (e, type) => {
        console.log('on change file', e.target);
        setCurrentFile(URL.createObjectURL(e.target.files[0]));
        setImageChanged(true);
        setCropperModal(true);
        setCurrentFileType(type);
    }

    const removeImage = (i) => {
        console.log('on remove image', i);
        console.log('currentFileType currentFileType', currentFileType);
        setFile(null);
        if (currentFileType == 'file')
            setFile(null);
        else {
            let data = Object.assign([], moreFile);
            data.splice(i, 1);
            setMoreFiles(data);
        }
        console.log(file);
    }

    const onEditorStateChange = (editorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setDesc(html);
        setDescContent(editorState);
    }

    const cropImage = (e, v) => {
        setLoading(true);
        const fileData = cropper?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (currentFileType == 'file')
            setFile(fileData);
        else {
            let data = Object.assign([], moreFile);
            data.push(fileData);
            setMoreFiles(data);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                60,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    const handleMultiSelect = (val, type) => {
        if (val == "")
            return;
        if (type == 'month') {
            let data = Object.assign([], selectedMonths);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedMonths(data);
            }
        }
        else if (type == 'exp') {
            let data = Object.assign([], selectedExp);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedExp(data);
            }
        }
        else if (type == 'who') {
            let data = Object.assign([], selectedWho);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedWho(data);
            }
        }
        else if (type == 'location') {
            let data = Object.assign([], selectedLocation);
            const found = data.findIndex(x => x == val);
            if (found == -1) {
                data.push(val);
                setSelectedLocation(data);
            }
        }
    }

    const handleRemoveMulti = (index, type) => {
        if (type == 'month') {
            let data = Object.assign([], selectedMonths);
            data.splice(index, 1);
            setSelectedMonths(data);
        }
        else if (type == 'exp') {
            let data = Object.assign([], selectedExp);
            data.splice(index, 1);
            setSelectedExp(data);
        }
        else if (type == 'who') {
            let data = Object.assign([], selectedWho);
            data.splice(index, 1);
            setSelectedWho(data);
        }
        else if (type == 'location') {
            let data = Object.assign([], selectedLocation);
            data.splice(index, 1);
            setSelectedLocation(data);
        }
    }

    const getName = (id, type) => {
        if (type == 'month') {
            let data = Object.assign([], meta?.months);
            let found = data.find(x => x._id == id);
            // console.log('found month obj', found);
            return found?.name;
        } else if (type == 'exp') {
            let data = Object.assign([], meta?.experiences);
            let found = data.find(x => x._id == id);
            // console.log('found exp obj', found);
            return found?.name;
        } else if (type == 'inspiration') {
            let data = Object.assign([], meta?.inspirations);
            let found = data.find(x => x._id == id);
            // console.log('found inspiration obj', found);
            return found?.name;
        } else if (type == 'who') {
            let data = Object.assign([], meta?.whos);
            let found = data.find(x => x._id == id);
            // console.log('found who obj', found);
            return found?.name;
        } else if (type == 'location') {
            let data = Object.assign([], meta?.locations);
            let found = data.find(x => x._id == id);
            // console.log('found location obj', found);
            return found?.name;
        } else {
            return "";
        }
    }

    const uploadMoreFiles = () => {
        setLoading(true);
        console.log('total files', moreFile.length);
        let banners = [];
        moreFile.map(async (item, index) => {
            await urltoFile(item, new Date().getTime() + '.png', 'image/png')
                .then(async (obj) => {
                    console.log('values', obj);
                    const image = await resizeFile(obj);
                    const form = new FormData();
                    form.append("image", image);
                    await upload("/property/image_upload", form)
                        .then(res => {
                            console.log('response from image upload', res);
                            if (res?.statusCode == 200) {
                                banners.push(res?.data);
                                if (index == moreFile.length - 1) {
                                    let body = {
                                        propertyId: currentData?._id,
                                        banners,
                                        token: user?.token,
                                    }
                                    let url = "/property/update";
                                    console.log('body after upload images', body);
                                    put(url, body)
                                        .then((json) => {
                                            console.log('response from adding comp', json);
                                            setLoading(false);
                                            if (json.statusCode == 200) {
                                                console.log('property more image updated!');
                                                toast.success(json?.message);
                                                props.close(false);
                                            } else {
                                                toast.error(json?.error);
                                            }
                                        })
                                        .catch(error => {
                                            console.error('error while adding oppor', error);
                                            toast.error(JSON.stringify(error));
                                            setLoading(false);
                                        })
                                }
                            }
                        }).catch(error => {
                            console.error('error while uploading images', error);
                            toast.error(JSON.stringify(error));
                            setLoading(false);
                        })
                })
        })
    }

    const handleContinentChanged = (id) => {
        let con = [];
        meta?.countries?.map(item => {
            if (item?.continentId == id)
                con.push(item);
        });
        setCountries(con);
    }

    return (
        <React.Fragment>
            <div>
                <ProgressBar
                    visible={progress < 1 ? false : true}
                    progress={progress}
                />
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    {file ?
                                        <div className="d-flex">
                                            <div className="me-3 position-relative">
                                                <img
                                                    src={file ? file : avatar}
                                                    alt=""
                                                    style={{ width: 1000, height: 'auto' }}
                                                />
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                    onClick={(e) => removeImage(0)}
                                                >
                                                    <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="me-3 position-relative">
                                            <img
                                                src={avatar}
                                                alt=""
                                                style={{ width: 1000, height: 'auto' }}
                                                onClick={(e) => imageInput.current.click(e)}
                                            />
                                            <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                onClick={(e) => imageInput.current.click(e)}
                                            >
                                                <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                            </div>
                                            <input type="file" id="file"
                                                ref={imageInput}
                                                onChange={(e) => onChangeFile(e, 'file')}
                                                style={{ display: "none" }} />
                                        </div>
                                    }
                                    {/* <div className="align-self-center">
                                        <div className="text-muted">
                                            <h5>{companyName}</h5>
                                            <p className="mb-1">ID: {data?.compId ? data?.compId : 'AP****'}
                                                <Button onClick={(e) => { navigator.clipboard.writeText(data?.compId); setCopied(true) }}
                                                    title={"Copy"}
                                                    style={{ backgroundColor: Utils.themeColor, marginLeft: 10, padding: 5, paddingLeft: 8, paddingRight: 8 }}><span className="mdi mdi-content-copy" style={{ fontSize: 20 }}></span></Button>
                                                {copied &&
                                                    <span style={{ color: 'green' }}>Copied!</span>
                                                }</p>
                                        </div>
                                    </div> */}
                                    <div className="text-center" style={{ marginLeft: 'auto' }}>
                                        <Button color="primary" onClick={() => { props.setCurrentData(null); props?.close(false) }} style={{ backgroundColor: Utils.themeColor }}>
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* Image cropper modal */}
                <Modal isOpen={cropperModal} toggle={() => { setCropperModal(false); setFile(null) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="p-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 'auto', width: 400 }}
                                        aspectRatio={16 / 5}
                                        preview=".img-preview"
                                        guides={true}
                                        src={currentFile}
                                        ref={cropper}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                {/* More image modal */}
                <Modal isOpen={moreImageModel} className="more_files" toggle={() => { setMoreImageModel(false); setMoreFiles([]) }} centered={true}>
                    <ModalBody className="py-3 px-5">
                        <h5 className="text-black font-size-20">More Images</h5>
                        <div className="p-2">
                            <div className="mb-3">
                                <Row>
                                    {moreFile.length > 0 &&
                                        <>
                                            {moreFile.map((item, index) => (
                                                <Col lg={3}>
                                                    <div className="me-3 position-relative mt-4">
                                                        <img
                                                            src={item ? item : avatar}
                                                            alt=""
                                                            style={{ width: 285, height: 'auto' }}
                                                        />
                                                        <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                            onClick={(e) => removeImage(index)}
                                                        >
                                                            <i className="mdi mdi-close" style={{ color: 'white', fontSize: 22 }} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </>
                                    }
                                    {moreFile.length < 8 &&
                                        <Col lg={3}>
                                            <div className="me-3 position-relative mt-4">
                                                <img
                                                    src={avatar}
                                                    alt=""
                                                    style={{ width: 285, height: 'auto' }}
                                                    onClick={(e) => imageInputMore.current.click(e)}
                                                />
                                                <div style={{ position: 'absolute', height: 40, width: 40, right: -10, top: -8, paddingTop: 5, backgroundColor: Utils.themeColor, borderRadius: 50, textAlignLast: 'center', cursor: 'pointer' }}
                                                    onClick={(e) => imageInputMore.current.click(e)}
                                                >
                                                    <i className="mdi mdi-camera-outline" style={{ color: 'white', fontSize: 22 }} />
                                                </div>
                                                <input type="file" id="file"
                                                    ref={imageInputMore}
                                                    onChange={(e) => onChangeFile(e, 'more')}
                                                    style={{ display: "none" }} />
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            <div className="mt-1">
                                <Row>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            style={{ backgroundColor: Utils.themeColor }}
                                            onClick={() => { setMoreImageModel(false); setMoreFiles([]); currentFile(null); props.close(false); }}
                                        >
                                            Cancel
                                        </button>
                                    </Col>
                                    <Col md={6}>
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            style={{ backgroundColor: Utils.themeColor }}
                                            onClick={uploadMoreFiles}
                                        >
                                            Save
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <h4 className="card-title mb-4">{data ? 'Update Card Details' : 'Add a Property'}</h4>

                <Card>
                    <CardBody>
                        <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                                handleValidSubmit(e, v)
                            }}
                        >
                            <Row>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="name"
                                            label="Title*"
                                            value={data?.name}
                                            className="form-control"
                                            placeholder="Enter Title"
                                            type="text"
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="continentId"
                                            label="Continent*"
                                            value={data?.continentId}
                                            className="form-control"
                                            type="select"
                                            onChange={(e) => handleContinentChanged(e.target.value)}
                                            required
                                        >
                                            <option value={""}>Select Continent</option>
                                            {meta?.continent?.map((item, index) => (
                                                <option value={item?._id}>{item?.name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="countryId"
                                            label="Country*"
                                            value={data?.countryId}
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value={""}>Select Country</option>
                                            {countries?.map((item, index) => (
                                                <option value={item?._id}>{item?.name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="months"
                                            label="Month*"
                                            className="form-control"
                                            type="select"
                                            multiple
                                            onChange={(e) => handleMultiSelect(e.target.value, 'month')}
                                        >
                                            <option value={""} >Select Month</option>
                                            {meta?.months?.map((item) => {
                                                return (
                                                    <option value={item?._id} >{item?.name}</option>
                                                )
                                            })}
                                        </AvField>
                                    </div>
                                    {selectedMonths?.length > 0 &&
                                        <div className="d-flex mb-4 flex-wrap">
                                            {selectedMonths.map((item, index) => {
                                                return (
                                                    <div style={{ border: `1px solid ${Utils.themeColor}`, marginRight: 10, display: 'flex', marginBottom: 15 }}>
                                                        <span style={{ padding: 5 }}>{getName(item, 'month')}</span>
                                                        <div style={{ backgroundColor: Utils.themeColor, height: '100%', width: 20, textAlign: 'center', cursor: 'pointer' }}
                                                            onClick={() => handleRemoveMulti(index, 'month')}
                                                        >
                                                            <span style={{ color: 'white', verticalAlign: 'sub' }} >X</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="location"
                                            label="Location*"
                                            className="form-control"
                                            type="text"
                                            value={data?.location}
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="lat"
                                            label="Latitude*"
                                            className="form-control"
                                            type="number"
                                            value={data?.lat}
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="long"
                                            label="Longitude*"
                                            className="form-control"
                                            type="number"
                                            value={data?.long}
                                        >
                                        </AvField>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="experience"
                                            label="Experience*"
                                            className="form-control"
                                            type="select"
                                            multiple
                                            onChange={(e) => handleMultiSelect(e.target.value, 'exp')}
                                        >
                                            <option value={""} >Select Experience</option>
                                            {meta?.experiences?.map((item) => {
                                                return (
                                                    <option value={item?._id} >{item?.name}</option>
                                                )
                                            })}
                                        </AvField>
                                    </div>
                                    {selectedExp?.length > 0 &&
                                        <div className="d-flex mb-4 flex-wrap">
                                            {selectedExp.map((item, index) => {
                                                return (
                                                    <div style={{ border: `1px solid ${Utils.themeColor}`, marginRight: 10, display: 'flex', marginBottom: 15 }}>
                                                        <span style={{ padding: 5 }}>{getName(item, 'exp')}</span>
                                                        <div style={{ backgroundColor: Utils.themeColor, height: '100%', width: 20, textAlign: 'center', cursor: 'pointer' }}
                                                            onClick={() => handleRemoveMulti(index, 'exp')}
                                                        >
                                                            <span style={{ color: 'white', verticalAlign: 'sub' }} >X</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="who"
                                            label="Who*"
                                            className="form-control"
                                            type="select"
                                            multiple
                                            onChange={(e) => handleMultiSelect(e.target.value, 'who')}
                                        >
                                            <option value={""} >Select Whos</option>
                                            {meta?.whos?.map((item) => {
                                                return (
                                                    <option value={item?._id} >{item?.name}</option>
                                                )
                                            })}
                                        </AvField>
                                    </div>
                                    {selectedWho?.length > 0 &&
                                        <div className="d-flex mb-4 flex-wrap">
                                            {selectedWho.map((item, index) => {
                                                return (
                                                    <div style={{ border: `1px solid ${Utils.themeColor}`, marginRight: 5, marginBottom: 15, display: 'flex' }}>
                                                        <span style={{ padding: 5 }}>{getName(item, 'who')}</span>
                                                        <div style={{ backgroundColor: Utils.themeColor, height: '100%', width: 20, textAlign: 'center', cursor: 'pointer' }}
                                                            onClick={() => handleRemoveMulti(index, 'who')}
                                                        >
                                                            <span style={{ color: 'white', verticalAlign: 'sub' }} >X</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </Col>
                                <Col md={6}>
                                    <div className="form-group mb-4 ">
                                        <AvField
                                            name="nights"
                                            label="Duration*"
                                            className="form-control"
                                            type="text"
                                            required
                                            value={data?.nights}

                                        />
                                    </div>
                                </Col>
                                <Col md={6} style={{ alignItems: 'center', marginTop: 20, marginBottom: 30 }}>
                                    <div className="form-group form_group_box mb-0 d-flex">
                                        <AvField
                                            name="isRare"
                                            label="Is Hidden Gems*"
                                            className="form-control"
                                            type="checkbox"
                                            value={data?.isRare}
                                            style={{ width: '20px', height: '25px', marginRight: '10px' }}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div className="form-group mb-4">
                                        <AvField
                                            name="overview"
                                            label="Short Description*"
                                            className="form-control"
                                            type="text"
                                            required
                                            value={data?.overview}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                {/* terms */}
                                <div className="form-group mb-4">
                                    {/* How it works */}
                                    <label>Description*</label>
                                    <Editor
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={(e) => onEditorStateChange(e)}
                                        editorState={descContent}
                                    />
                                </div>
                            </Row>
                            {/* <AvField name="idx" value={user?.company._id} type="hidden" /> */}
                            <div className="text-center mt-4">
                                {data ?
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Update Property
                                    </Button>
                                    :
                                    <Button type="submit" style={{ border: 'none', backgroundColor: Utils.themeColor }}>
                                        Add Property
                                    </Button>
                                }
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

AddProperty.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    companyError: PropTypes.func,
    companySuccess: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func
}

const mapStatetoProps = state => {
    const { user } = state.Profile;
    return { user }
}

export default withRouter(
    connect(mapStatetoProps, {})(AddProperty)
)
